import Client1 from '../../Images/Client1.png';
import Client2 from '../../Images/Client2.png';
import Client3 from '../../Images/Client3.png';
import Client4 from '../../Images/Client4.png';
import OurClients from '../../Images/our-clients.png';
import './Clients.scss';

export default function Clients() {
  return (
    <div id='Clients' className='py-9 mx-auto max-w-[1440px]'>
      <div className='top-0 mx-auto'>
        <div className='flex justify-center items-center pb-2 md:pb-4 lg:pb-8'>
          <div className='header-container'>
            <h1>A Selection of Our Clients</h1>
          </div>
        </div>
      </div>
      <div className='clients-container'>
        <div className='client-container'>
          <img className='client-img' src={Client1} alt='Client1' />
          <h2>INZO</h2>
        </div>
        <div className='client-container'>
          <img className='client-img' src={Client2} alt='Client2' />
          <h2>Maxify</h2>
        </div>
        <div className='client-container'>
          <img className='client-img' src={Client3} alt='Client3' />
          <h2>GoDo</h2>
        </div>
        <div className='client-container'>
          <img className='client-img' src={Client4} alt='Client4' />
          <h2>Private Finance</h2>
        </div>
      </div>
      {/* <div className='sm:mx-8 width-full pt-4 flex flex-col items-center lg:items-start lg:grid lg:gap-6 lg:grid-cols-3'>
        <div className='md:max-w-[95%] lg:max-w-sm h-full lg:h-full mb-[15px] sm:mb-[20px] bg-white border border-violet-200 rounded-lg shadow dark:bg-violet-800 dark:border-violet-700 overflow-hidden transform transition-all duration-300 hover:scale-95'>
          <div className='p-3 md:p-5 w-[300px] sm:w-full h-full flex flex-col sm:flex-row lg:flex-col items-center'>
            <img
              className='aspect-[4/3] object-cover object-top w-full sm:w-1/2 lg:w-full rounded p-2.5 lg:p-[20px] m-[2px] sm:m-[10px] lg:m-0 lg:mb-[30px] bg-[#8787871a]'
              src={Client1}
              alt='Client1'
            />
          </div>
        </div>
        <div className='md:max-w-[95%] lg:max-w-sm h-full lg:h-full mb-[15px] sm:mb-[20px] bg-white border border-violet-200 rounded-lg shadow dark:bg-violet-800 dark:border-violet-700 overflow-hidden transform transition-all duration-300 hover:scale-95'>
          <div className='p-3 md:p-5 w-[300px] sm:w-full h-full flex flex-col sm:flex-row lg:flex-col items-center'>
            <img
              className='aspect-[4/3] object-cover object-top w-full sm:w-1/2 lg:w-full rounded p-2.5 lg:p-[20px] m-[2px] sm:m-[10px] lg:m-0 lg:mb-[30px] bg-[#8787871a]'
              src={Client2}
              alt='Client2'
            />
          </div>
        </div>
        <div className='md:max-w-[95%] lg:max-w-sm h-full lg:h-full mb-[15px] sm:mb-[20px] bg-white border border-violet-200 rounded-lg shadow dark:bg-violet-800 dark:border-violet-700 overflow-hidden transform transition-all duration-300 hover:scale-95'>
          <div className='p-3 md:p-5 w-[300px] sm:w-full h-full flex flex-col sm:flex-row lg:flex-col items-center'>
            <img
              className='aspect-[4/3] object-cover object-top w-full sm:w-1/2 lg:w-full rounded p-2.5 lg:p-[20px] m-[2px] sm:m-[10px] lg:m-0 lg:mb-[30px] bg-[#8787871a]'
              src={Client3}
              alt='Client3'
            />
          </div>
        </div>
      </div> */}
    </div>
  );
}
